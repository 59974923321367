<template>
<div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
  <!--begin::Content body-->
  <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
    <!--begin::Signin-->
    <div class="login-form login-signin w-100">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">{{ $t('AUTH.RESET.TITLE') }}</h3>
        <p class="text-muted font-weight-semi-bold">
          {{ $t('AUTH.RESET.DESC') }}
        </p>
      </div>
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="8" offset-md="2">
            <!--begin::Form-->
            <b-form class="form" @submit.stop.prevent="onSubmit">
              <b-form-group :label="`${$t('FORM.PASSWORD')}:`" label-for="input-3">
                <InputForm
                  id="input-3"
                  type="password"
                  v-model="$v.form.newPassword.$model"
                  :state="validateState('newPassword')"
                  :placeholder="$t('PLACEHOLDER.PASSWORD')"
                  @input="clearServerErrors"
                  aria-describedby="input-3-feedback"
                />
                <b-form-invalid-feedback id="input-3-feedback">
                  <template v-if="serverErrors.newPassword">{{ serverErrors.newPassword[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PASSWORD') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <!--begin::Action-->
              <div
                class="form-group d-flex flex-wrap justify-content-between align-items-center"
              >
                <router-link :to="{ name: 'login' }"
                             class="text-color-dark text-dark-60 text-hover-primary my-3 mr-2"
                             id="kt_login_forgot"
                >{{ $t('AUTH.SIGN_IN') }}</router-link>
                <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="info" size="sm">
                  <b-spinner v-if="loading" small variant="light" />
                  {{ $t('FORM.SUBMIT') }}
                </b-button>
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Form-->
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!--end::Signin-->
  </div>
  <!--end::Content body-->
</div>
</template>

<script>
import { RESTORE_PASS_RESET } from '@/core/services/store/auth.module'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import InputForm from '../../../components/forms-items/input'
import serverVuelidate from '../../../mixins/serverVuelidate'

export default {
  name: 'ResetPass',
  components: { InputForm },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      form: {
        newPassword: '',
      },
      validations: {
        form: {
          newPassword: {
            required,
          },
        },
      },
    }
  },
  mounted() {
    if (!this.$route.query.token) this.$router.replace({ name: 'login' })
  },
  methods: {
    resetForm() {
      this.form = {
        newPassword: '',
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      if (!this.$route.query.token) this.onRedirect()
      this.$store.dispatch(RESTORE_PASS_RESET, {
        token: this.$route.query.token,
        ...this.form, 
      })
        .then(() => {
          this.$bvToast.toast(this.$t('AUTH.RESET.PASS_CHANGED'), {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 2000,
            solid: true,
          })
          this.resetForm()
          setTimeout(() => this.$router.replace({ name: 'login' }), TIMEOUT_REDIRECT)
          this.loading = false
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
        })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
